<template>
  <div class="wrap">
    <div id="header">
      <div class="title">
        <span>{{ ecology.title }}</span>
      </div>
      <div class="sub-title">{{ ecology.subTitle }}</div>
      <div class="des">{{ ecology.des }}</div>
    </div>
    <div
      class="panel"
      v-for="(item, index) in panelDataList"
      :key="item.name"
      @click="handleToPage(item.link)"
    >
      <div class="logo">
        <svg class="icon" aria-hidden="true">
          <use :xlink:href="item.icon"></use>
        </svg>
      </div>
      <div class="info">
        <div class="font-info">
          <div class="name">
            <div>{{ item.name }}</div>
            <div @click.stop="handleShowTotal" v-if="index === 0" class="show">
              <span v-show="!eggShowTotal">{{ ecology.more }}</span>
              <span v-show="eggShowTotal">{{ ecology.less }}</span>
            </div>
          </div>
          <div
            class="des"
            :class="{ 'egg-des': !eggShowTotal }"
            v-if="index === 0"
          >
            {{ ecology.eggDes }}
          </div>
          <div class="des" v-else>{{ ecology.mDaoDes }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Ecology',
  data () {
    return {
      panelDataList: [
        {
          icon: '#icon-a-zu217',
          name: 'EGG-DAO',
          link: 'https://eggdao.io/'
        }
        // {
        //   icon: '#icon-a-zu2061',
        //   name: 'M-DAO',
        //   link: ''
        // }
      ],
      eggShowTotal: false
    }
  },
  computed: {
    ...mapState(['mbLang']),
    ecology () {
      return this.$t('ecology')
    }
  },
  methods: {
    handleToPage (link) {
      if (link) {
        window.open(link)
      } else {
        return false
      }
    },
    handleShowTotal () {
      this.eggShowTotal = !this.eggShowTotal
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: 15px;
}
#header {
  background-color: #f8f0d9;
  padding: 70px 30px 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: url('../../static/img/ecology/eggBanner.png') no-repeat #fbf7eb;
  background-size: 100% auto;
  .title {
    color: #34231a;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #f4e7c3;
    padding-bottom: 5px;
    line-height: 1em;
    display: inline-block;
  }
  .sub-title {
    padding-top: 15px;
    color: #766452;
    font-size: 16px;
  }
  .des {
    padding-top: 30px;
    color: #766452;
    font-size: 12px;
  }
}
.egg-des {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.panel {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  .logo {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
    background: rgba(239, 239, 239, 0.39);
    border-radius: 50%;
    .icon {
      width: 40px;
      height: 40px;
    }
  }
  .info {
    // display: flex;
    width: 80%;
  }
  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #33231a;
    text-indent: 1.3em;
    // width: 90%;
    font-size: 18px;
  }
  .des {
    padding-top: 10px;
    color: #33231a;
    text-indent: 2em;
    font-size: 12px;
    // width: 90%;
  }
  .show {
    // width: 40px;
    font-size: 12px;
    span {
      border-bottom: 2px solid #f4e7c3;
    }
  }
}
</style>
